<template>
    <transition name="slide-up">
        <div class="content-container content-half" v-if="isActive">
            <div class="flex-column">
                <div class="d-flex">
                    <!--<div class="question-number">3-</div>-->
                    <div class="flex-column padding-div">
                        <div>
                            <div class="fs-28px fw-700 color-dark">Você é profissional autônomo ou trabalha em uma empresa?</div>
                            <div class="mt-5">
                                <div class="select-boxes-row">
                                    <div v-bind:class="(selection == true ? 'select-box select-box-1 active': 'select-box select-box-1')" @click="select(true)">
                                        <p class="mb-0">Trabalho em uma empresa</p>
                                    </div>
                                    <div v-bind:class="(selection == false ? 'select-box select-box-1 active': 'select-box select-box-1')" @click="select(false)">
                                        <div class="valia-tooltip-no-text-decoration">
                                            <div class="select-box-muted">
                                                <p class="mb-0">Sou profissional autônomo</p>
                                                </div>
                                                <span class="valia-tooltiptext" v-if="fluxo=='aberturamaior18'">
                                                    A cobrança da contribuição no valor de R${{humanFormat(getPessoa["perfil-plano"]["valor-contribuicao"].replace('.', '').replace(',', '.'))}} para o Plano Prevaler será realizada através de boleto.
                                                </span>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>
<script>
    import 'bootstrap';
    import { mapState } from "vuex";
    export default {
        name: 's01-representante',
        props: ['pessoa', 'visible', 'setpessoadata', 'fluxo'],
        data: () => ({
            options: {
                locale: "pt-BR",
                length: 11,
                precision: 2,
                empty: null,
            },
            isActive: false,
            loading: false,
            selection: null,
            step: 1,
            valInicial: false,
            pessoaCorporativa: null,
            isParticipante: false,
            isRepresentante: false,
            isDependente: false,
            detalhes: null,
            detalhesEndereco: null
        }),
        watch: {
            visible: function () {
                this.toggle();
            }
        },
        computed: {
            ...mapState(["pessoaCorporativo"]),
            ...mapState(["token"]),
            getPessoa() {
                return this.pessoa
            },
        },
        methods: {
            humanFormat: function (value) {
                if (value || value === 0) {
                    value = Number(value).toLocaleString(this.options.locale, {
                        maximumFractionDigits: this.options.precision,
                        minimumFractionDigits: this.options.precision,
                    });
                } else {
                    value = this.options.empty;
                }
                return value;
            },
            toggle() {
                this.isActive = !this.isActive;
                if (this.isActive) {
                    this.habilitaOpcoes();
                }
            },
            select(val) {
                this.selection = val;
                this.next();
            },
            async habilitaOpcoes() {
                if (this.pessoa["perfil-pessoal"] === undefined || this.pessoa["perfil-pessoal"] == null)
                    this.pessoa["perfil-pessoal"] = {};
                if (this.pessoa["perfil-pessoal"] !== undefined && this.pessoa["perfil-pessoal"].nomeEmpresa !== undefined && this.pessoa["perfil-pessoal"].nomeEmpresa != null) {
                    if (this.pessoa["perfil-pessoal"].nomeEmpresa != "" && (this.pessoa["perfil-pessoal"].nomeEmpresa != "Autônomo"))
                        this.selection = true;
                    if (this.pessoa["perfil-pessoal"].nomeEmpresa == "Autônomo")
                        this.selection = false;
                }
            },
            next: function () {
                if (this.selection == false) {
                    this.pessoa["perfil-pessoal"].codigoEmpresa = null;
                    this.pessoa["perfil-pessoal"].nomeEmpresa = "Autônomo";
                    this.$emit('nextempresa');
                }
                else {
                    this.pessoa["perfil-pessoal"].nomeEmpresa = null;
                    this.pessoa["perfil-pessoal"].codigoEmpresa = null;
                    this.$emit('next');
                }
            },
            back: function () {
                this.$emit('back');
            }
        },
    };
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

