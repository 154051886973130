<template>
    <div>
        <div class="valia-content">
            <Header />
            <div class="valia-progress">
                <v-progress-linear v-model="completition"
                                   bg-color="#d8e5d3" height="6"
                                   color="#F27324"></v-progress-linear>
            </div>
            <BgEscolhas v-if="step!=21 && step!=27" />
            <BgFim v-else-if="step==27" />
            <BgFinalizar v-else-if="step==21" />
            <v-container class="position-relative z-index-1 question-container">
                <div class="question-container">

                    <s25_inscricao @next="create" v-bind:fluxo="nomeFluxo" v-bind:responsavelpelomenor="responsavelpelomenor" :visible="step==0" number="0"></s25_inscricao>
                    <s01_representante_2 @next="gonext" v-bind:pessoa="pessoa" v-bind:responsavelpelomenor="responsavelpelomenor" :fluxo="nomeFluxo" :visible="step==1" number="1"></s01_representante_2>

                    <s00_inicio @next="gonext" v-bind:pessoa="pessoa" :fluxo="nomeFluxo" :visible="step==2" number="2"></s00_inicio>

                    <!--                    <s01_representante @next="gonext" @nextrepresentante="gonextrepresentante" v-bind:pessoa="pessoa" v-bind:responsavelpelomenor="responsavelpelomenor" :fluxo="nomeFluxo" @back="goback" :visible="step==2" number="2"></s01_representante>
                <s01_representante_2 @next="gonext" v-bind:pessoa="pessoa" v-bind:responsavelpelomenor="responsavelpelomenor" :fluxo="nomeFluxo" @back="goback" :visible="step==2" number="2"></s01_representante_2>

            <s01_email @next="gonext" v-bind:pessoa="pessoa" :fluxo="nomeFluxo" @back="goback" :visible="step==3" number="3"></s01_email>
                            <s02_celular @next="gonext" v-bind:pessoa="pessoa" :fluxo="nomeFluxo" @back="goback" :visible="step==4"></s02_celular>-->
                    <s04_endereco @next="gonext" v-bind:pessoa="pessoa" :fluxo="nomeFluxo" @back="goback" :visible="step==3"></s04_endereco>
                    <s23_sexo @next="gonext" v-bind:pessoa="pessoa" :fluxo="nomeFluxo" @back="goback" :visible="step==4"></s23_sexo>
                    <s01_contribuicao @next="gonext" v-bind:pessoa="pessoa" :fluxo="nomeFluxo" @back="goback" :visible="step==5"></s01_contribuicao>


                    <s24_trabalha @next="gonext" @nextempresa="gonextempresa" v-bind:pessoa="pessoa" :fluxo="nomeFluxo" :visible="step==6" number="6"></s24_trabalha>

                    <s24_trabalha_2 @next="gonext" @nextempresa="gonextempresa2" v-bind:pessoa="pessoa" :fluxo="nomeFluxo" :visible="step==7" number="7"></s24_trabalha_2>

                    <s02_descontofolha @next="gonext" v-bind:pessoa="pessoa" :fluxo="nomeFluxo" @back="goback" :visible="step==8" number="8"></s02_descontofolha>

                    <s07_rendimento_mensal @next="gonext" v-bind:pessoa="pessoa" :fluxo="nomeFluxo" @back="goback" :visible="step==9"></s07_rendimento_mensal>
                    <s03_ppe @next="gonext" v-bind:pessoa="pessoa" :fluxo="nomeFluxo" setpessoadata="false" @back="goback" :visible="step==10"></s03_ppe>

                    <s04_preencheranalise @next="gonext" v-bind:pessoa="pessoa" :fluxo="nomeFluxo" v-bind:opcaoanalise="opcaoanalise" @back="goback" :visible="step==11" @skipquestionario="skipquestionario"></s04_preencheranalise>
                    <div class="fs-24px valia-item-description secondary-text vamos-descobrir-perfil" v-if="step == 12 || step == 13 || step == 14 || step == 15 || step == 16 || step == 17 || step == 18">
                        <!--<span class="fs-24px fw-600">4- </span>-->Vamos descobrir o seu perfil de investimento.
                    </div>
                    <s04_preencheranalise_1 @next="gonext" @back="goback" @selectquiz="doselectquiz" @skipquestionario="skipquestionario" :visible="step==12"></s04_preencheranalise_1>
                    <s04_preencheranalise_2 @next="gonext" @back="goback" @selectquiz="doselectquiz" @skipquestionario="skipquestionario" :visible="step==13"></s04_preencheranalise_2>
                    <s04_preencheranalise_3 @next="gonext" @back="goback" @selectquiz="doselectquiz" @skipquestionario="skipquestionario" :visible="step==14"></s04_preencheranalise_3>
                    <s04_preencheranalise_4 @next="gonext" @back="goback" @selectquiz="doselectquiz" @skipquestionario="skipquestionario" :visible="step==15"></s04_preencheranalise_4>
                    <s04_preencheranalise_5 @next="gonext" @back="goback" @selectquiz="doselectquiz" @skipquestionario="skipquestionario" :visible="step==16"></s04_preencheranalise_5>
                    <s04_preencheranalise_6 @next="gonext" @back="goback" @selectquiz="doselectquiz" @skipquestionario="skipquestionario" :visible="step==17"></s04_preencheranalise_6>
                    <s04_preencheranalise_7 v-bind:perfilInvestidor="perfilInvestidor" @next="gonext" @back="goback" @selectperfilquiz="selectperfilquiz" :visible="step==18"></s04_preencheranalise_7>

                    <s05_revise @next="gonext" v-bind:perfilInvestidor="perfilInvestidor" @back="goback" :descontofolhaativo="descontofolhaativo" :visible="step==19" v-bind:pessoa="pessoa" :fluxo="nomeFluxo"></s05_revise>
                    <s06_termos @next="gonext" @back="goback" :visible="step==20" v-bind:pessoa="pessoa" :fluxo="nomeFluxo"></s06_termos>
                    <s22_upload_documentos @next="gonext" v-bind:pessoa="pessoa" :fluxo="nomeFluxo" :visible="step==21" number="21"></s22_upload_documentos>

                    <!--<s24_trabalha @next="gonext" @nextempresa="gonextempresa" v-bind:pessoa="pessoa" :fluxo="nomeFluxo" :visible="step==19" number="19"></s24_trabalha>

            <s24_trabalha_2 @next="gonext" v-bind:pessoa="pessoa" :fluxo="nomeFluxo" :visible="step==20" number="20"></s24_trabalha_2>-->

                    <s07_quase_terminando @next="gonext" v-bind:pessoa="pessoa" :fluxo="nomeFluxo" @back="goback" @concluir="concluir" @definirIdadeEBeneficiarios="definirIdadeEBeneficiarios" :visible="step==22"></s07_quase_terminando>
                    <s08_idade_beneficio @next="gonext" v-bind:pessoa="pessoa" :fluxo="nomeFluxo" @back="goback" :visible="step==23" @cadastrarBeneficiarioNao="cadastrarBeneficiarioNao"></s08_idade_beneficio>
                    <s09_cadastrar_beneficiario @next="gonext" v-bind:pessoa="pessoa" :fluxo="nomeFluxo" @back="goback" @cadastrarBeneficiarioNao="cadastrarBeneficiarioNao" :visible="step==24"></s09_cadastrar_beneficiario>
                    <s09_cadastrar_beneficiario_2 @next="gonext" v-bind:pessoa="pessoa" :fluxo="nomeFluxo" @back="goback" :visible="step==25" number="25"></s09_cadastrar_beneficiario_2>
                    <s10_sms @next="gonext" @back="goback" v-bind:pessoa="pessoa" :fluxo="nomeFluxo" :visible="step==26"></s10_sms>
                    <s10_sms_token @next="gonext" @back="goback" v-bind:pessoa="pessoa" :fluxo="nomeFluxo" :visible="step==27"></s10_sms_token>
                    <s11_parabens @next="gonext" @back="goback" v-bind:pessoa="pessoa" :fluxo="nomeFluxo" :visible="step==28" @savepessoa="savePessoa"></s11_parabens>
                    
                    <div class="d-flex justify-content-end align-items-center mr-4 mt-1">
                        <button @click="goback();" v-if="step>2 && step<28" class="button-outline-arrow">
                            <v-icon>expand_less</v-icon>
                        </button>
                    </div>                  

                </div>
            </v-container>
        </div>
        <!-- Modal -->
        <div class="modal fade valia-modal" id="modalDesistirPreenchimentoQuestionario" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="modalDesistirPreenchimentoQuestionarioLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" style="z-index:99 !important;">
                <div class="modal-content">

                    <div class="modal-body">
                        <div class="fs-24px color-primary fw-700 text-center">Atenção!</div>
                        <div class="mt-3">
                            <div class="fs-18px">
                                <!--Desistindo agora, você será alocado no
    <p class="fs-22px valia-tooltip mb-0">
        Perfil Base.
        <span class="valia-tooltiptext"><span class="fw-600">Perfil de Investimento Base</span> é o menor risco dentre os perfis  oferecidos. As aplicações são feitas apenas em renda fixa e buscam seguir o índice CDI (Certificados de Depósito Interbancário). Existem também os perfis de investimento: <span class="fw-600">Moderado, Multi, Ative</span> e <span class="fw-600">Intense</span>. Você pode escolher qualquer perfil, a qualquer tempo, após preencher o API (Análise de perfil de Investidor). <br />Para saber mais sobre perfil, acesse: <a class="valia-link-white" target="_blank" href="https://www.prevaler.com.br/como-funciona/#investimento">https://www.prevaler.com.br/como-funciona/#investimento</a>.</span>
    </p>
    Caso deseje futuramente mudar seu perfil de investimento, será necessário preencher seu API (Análise de Perfil de Investidor).-->
                                Desistindo agora, você será alocado no Perfil Base. Caso deseje futuramente mudar seu perfil de investimento, será necessário preencher seu API (Análise de Perfil de Investidor).
                            </div>
                        </div>
                        <div class="mt-5 d-flex flex-column align-items-center">
                            <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12">
                                <button class="button-primary-bg-primary w-100 modal-button" @click="skipquestionario()" data-bs-dismiss="modal">
                                    Desistir do preenchimento
                                </button>
                                <button type="button" class="btn fill-after-w-100 mt-3 w-100 modal-button color-gray" data-bs-dismiss="modal">
                                    Continuar a preencher
                                </button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <!-- Modal 2-->
        <div class="modal fade valia-modal" id="modalPreenchimentoQuestionarioDiferenteSugestao" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="modalPreenchimentoQuestionarioDiferenteSugestao" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" style="z-index:99 !important;">
                <div class="modal-content">

                    <div class="modal-body">
                        <div class="fs-24px color-primary fw-700 text-center">Atenção!</div>
                        <div class="mt-3">
                            <div class="fs-18px">
                                Identificamos que o perfil de investimento escolhido não é adequado ao resultado obtido através do questionário API - Análise de Perfil do Investidor. Confirma a alteração de perfil de investimento?
                            </div>
                        </div>
                        <div class="mt-5 d-flex flex-column align-items-center">
                            <div class="col-xl-6 col-lg-6 col-md-8 col-sm-12">
                                <button class="button-primary-bg-primary w-100 modal-button" @click="gonext()" data-bs-dismiss="modal">
                                    Sim
                                </button>
                                <button type="button" class="btn fill-after-w-100 mt-3 w-100 modal-button" data-bs-dismiss="modal">
                                    Não
                                </button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    </div>

</template>

<script>

    import Header from '../components/Header.vue';
    import BgEscolhas from '../components/BgEscolhas.vue';
    import BgFim from '../components/BgFim.vue';
    import BgFinalizar from '../components/BgFinalizar.vue';
    import s00_inicio from './steps/s00-inicio.vue';
    import s01_contribuicao from './steps/s01-contribuicao.vue';

    //import s01_email from './steps/s01-email.vue';
    //import s02_celular from './steps/s02-celular.vue';
    import s04_endereco from './steps/s04-endereco.vue';
    import s02_descontofolha from './steps/s02-descontofolha.vue';
    import s03_ppe from './steps/s03-ppe.vue';
    import s04_preencheranalise from './steps/s04-preencheranalise.vue';
    import s04_preencheranalise_1 from './steps/s04-preencheranalise-1.vue';
    import s04_preencheranalise_2 from './steps/s04-preencheranalise-2.vue';
    import s04_preencheranalise_3 from './steps/s04-preencheranalise-3.vue';
    import s04_preencheranalise_4 from './steps/s04-preencheranalise-4.vue';
    import s04_preencheranalise_5 from './steps/s04-preencheranalise-5.vue';
    import s04_preencheranalise_6 from './steps/s04-preencheranalise-6.vue';
    import s04_preencheranalise_7 from './steps/s04-preencheranalise-7-resultado.vue';
    import s05_revise from './steps/s05-revise.vue';
    import s06_termos from './steps/s06-termos.vue';
    import s07_quase_terminando from './steps/s07-quase-terminando.vue';
    import s08_idade_beneficio from './steps/s08-idade-beneficio.vue';
    import s09_cadastrar_beneficiario from './steps/s09-cadastrar-beneficiario.vue';
    import s09_cadastrar_beneficiario_2 from './steps/s09-cadastrar-beneficiario-2.vue';
    import s10_sms from './steps/s10-sms.vue';
    import s10_sms_token from './steps/s10-sms-token.vue';
    import s11_parabens from './steps/s11-parabens.vue';
     
    /*import s03_identificacao from './steps/s03-identificacao.vue';*/
    import s07_rendimento_mensal from './steps/s07-rendimento-mensal.vue';


    //import s01_representante from './steps/s01-representante.vue';
    import s01_representante_2 from './steps/s01-representante-2.vue';
    import s23_sexo from './steps/s23-sexo.vue';
    import s25_inscricao from './steps/s25-inscricao.vue';
    import s24_trabalha from './steps/s24-trabalha.vue';
    import s24_trabalha_2 from './steps/s24-trabalha-2.vue';

    import s22_upload_documentos from './steps/s22-upload-documentos.vue';

    import { mapState } from "vuex";
    import NotificationService from '../services/notifications/NotificationService';
    
    import Profile from "../services/profile/Profile";
    import Common from "../services/common/Common";
    import CorporativoAPI from '../services/CorporativoAPI';

    export default {
        name: 'app',
        components: {
            Header,
            BgEscolhas,
            BgFim,
            s00_inicio,
            s01_contribuicao,
            //s01_email,
            //s02_celular,
            s02_descontofolha,
            s03_ppe, s04_endereco,
            s04_preencheranalise,
            s04_preencheranalise_1,
            s04_preencheranalise_2,
            s04_preencheranalise_3,
            s04_preencheranalise_4,
            s04_preencheranalise_5,
            s04_preencheranalise_6,
            s04_preencheranalise_7,
            s05_revise,
            s06_termos,
            s07_quase_terminando,
            s08_idade_beneficio,
            s09_cadastrar_beneficiario,
            s09_cadastrar_beneficiario_2,
            s10_sms,
            s10_sms_token,
            s11_parabens,
            s23_sexo,
            //s03_identificacao,
            s07_rendimento_mensal, 
            //s01_representante,
            s01_representante_2,
            s22_upload_documentos,
            s25_inscricao,
            s24_trabalha,
            s24_trabalha_2,

            BgFinalizar,

        },
        data: () => ({
            descontofolhaativo:false,
            nomeFluxo: "", 
            key: null,
            loading: false,
            step: -1,
            title: process.env.VUE_APP_TEST_TITLE,
            pessoa: null,
            grausDeParentesco: null,
            arquivosPedidosFiliacao: null,
            arquivosTermosAdesao: null,

            cpf: null,
            protocolo: null,


            perfilInvestidor: {},

            breadCrumb: [],
            opcaoanalise: null,

            protocoloadesaoSent: false,
            protocoloadesaoAberturaSent: false,
            informacoesperfilinvestimentoSent: false,
            adesaopendenteanaliseSent: false,
            formapagamentoboletoSent: false,
            formapagamentofolhaSent: false,
            responsavelpelomenor: true,

            naoPossuiaDadosRepresentante: false,
        }),
        computed: {
            ...mapState(["questionario"]),
            ...mapState(["token"]),
            ...mapState(["pessoaCorporativo"]),
            
            ...mapState(["userIP"]),
            ...mapState(["grausParentesco"]),
            ...mapState(["parentesco"]),
            ...mapState(["tiposRepresentacao"]),
            ...mapState(["smscode"]),
            ...mapState(["patrocinado"]),
            completition: {
                get() {
                    return 3.57 * this.step;
                }
            }

        },
        methods: { 

            gonextempresa(val) {
                console.log(val);
                this.breadCrumb.push(this.step);
                this.pessoa["perfil-plano"]["forma-cobranca"] = "b";
                this.step++; this.step++; this.step++;
            },

            gonextempresa2(val) {
                console.log(val);
                this.breadCrumb.push(this.step);

                this.descontofolhaativo = false;

                if (this.pessoa['perfil-pessoal'].codigoEmpresa != null && this.nomeFluxo == 'aberturamaior18') {
                    this.descontofolhaativo = true;
                }

                if (this.pessoa["perfil-pessoal"].codigoEmpresa == null || this.nomeFluxo == "aberturamenor18") {
                    this.pessoa["perfil-plano"]["forma-cobranca"] = "b";
                    this.step++; this.step++; 
                }
                else {
                    this.step++; 
                }
            },

            savePessoa() {
               Common.putPessoa(this.pessoa.id, this.pessoa);
            },
            gonext() {
                
                var entryPoint = window.location.href;
                var lStep = this.step + 1;
                switch (lStep) {
                    case 3: //inciar adesao
                        this.pessoa["participante-valia"].novoPortal = true;
                        this.pessoa["autoriza-notificacoes"] = true;
                        this.pessoa["autoriza-uso-dados"] = true;
                        this.pessoa["autoriza-compartilhamento-dados"] = true;
                        this.savePessoa();

                        var lparentesco = "PRÓPRIO(A)";
                        this.$store.commit("saveParentesco", lparentesco);
                        if (!this.protocoloadesaoSent) {
                            NotificationService.protocolocriacaoadesaoAbertura(this.nomeFluxo, this.pessoa, this.parentesco, this.userIP);
                            this.protocoloadesaoSent = true;
                        }

                        break; 
                    case 4: //na tela depois do email, envia o protocolo de adesao
                        if (!this.protocoloadesaoAberturaSent) {
                            NotificationService.protocoloadesaoAbertura(this.nomeFluxo, this.pessoa, this.parentesco, entryPoint);
                            this.protocoloadesaoAberturaSent = true;
                        }
                        break; 
                    case 5:  //contribuicao
                        this.savePessoa();
                        break;
                    case 7:  //trabalha 2
                        this.savePessoa();
                        break;
                    case 13:  //ppe
                        this.savePessoa();
                        break;
                    case 14:
                        this.opcaoanalise = true;
                        break;
                    case 19:  //terminou questionario
                        this.savePessoa();
                        break;
                    case 21:  
                        this.savePessoa();
                        break;
                    case 22:  
                        this.savePessoa();
                        break;
                    case 26:  //Beneficiarios salvos
                        this.savePessoa();
                        break;
                    case 27:  //telefone atualizado
                        if (this.nomeFluxo == "aberturamenor18") {
                            this.pessoa.telefone = {};
                            this.pessoa.telefone.ddi = "55";
                            this.pessoa.telefone.ddd = this.pessoa.representante.telefone.ddd
                            this.pessoa.telefone.numero = this.pessoa.representante.telefone.numero
                            this.pessoa.telefone.tipo = "1";
                        }
                        this.savePessoa();
                        break;
                    case 28:  //atualiza docs (chamado pela propria tela via emit)
                        //    this.savePessoa();

                        if (!this.informacoesperfilinvestimentoSent) {
                            NotificationService.informacoesperfilinvestimento(this.nomeFluxo, this.pessoa, this.userIP, this.questionario);
                            this.informacoesperfilinvestimentoSent = true;
                        }

                        if (!this.adesaopendenteanaliseSent) {
                            NotificationService.adesaopendenteanalise(this.nomeFluxo, this.pessoa, this.parentesco, this.userIP, this.smscode);
                            this.adesaopendenteanaliseSent = true;
                        }
                                            
                        break;
                    
                }

                this.breadCrumb.push(this.step);

                this.step++;

            },
            goback() {

                this.step = this.breadCrumb.pop();

            },
            concluir() {
                if (this.step == 22)
                    this.breadCrumb.push(this.step);

                this.step = 26;
            },
            definirIdadeEBeneficiarios() {
                if (this.step == 22)
                    this.breadCrumb.push(this.step);

                this.step = 23;
            },
            cadastrarBeneficiarioNao() {
                if (this.step == 24 || this.step == 25)
                    this.breadCrumb.push(this.step);

                this.step = 26;
            },
             async doselectquiz(evt, questao, valor) { //
                await Profile.doselectquiz(evt, questao, valor, this.questionario, this.perfilInvestidor);
            }
            ,
            async skipquestionario() {
                this.perfilInvestidor = {};
                 await Profile.skipquestionario(this.questionario, this.perfilInvestidor);
               
               this.pessoa["perfil-investidor"] = this.perfilInvestidor;

                this.opcaoanalise = false;

                //remover eventuais passos
                for (var i = 0; i < this.breadCrumb.length; i++) {

                    if (this.breadCrumb[i] >= 12 && this.breadCrumb[i] <= 18) {
                        this.breadCrumb.splice(i, 1);
                        i--;
                    }
                }

                if (this.step == 11)
                    this.breadCrumb.push(this.step);

                this.step = 19;
            },
             
            async selectperfilquiz(evt, perfilSelecionado, perfilSugerido) {
                await Profile.selectperfilquiz(perfilSelecionado, perfilSugerido, this.perfilInvestidor, this.questionario);
                this.pessoa["perfil-investidor"] = this.perfilInvestidor;
            },
            async startFlow() {
                //this.key = this.$route.query.id;

                //var r = Common.decodeCRUrl(this.key);
                //this.cpf = r.cpf;
                //this.protocolo = r.protocolo;



                //Common.loadInitialDataAbertura();
                //this.pessoa = await Common.loadPessoa(this.cpf, this.protocolo);
                //Common.loadPessoaCorporativo(this.pessoa, true);
                //this.perfilInvestidor = this.pessoa["perfil-investidor"];


                //this.step = 0;
            },
            async create(a) {
                this.pessoa = a.pessoa;
                this.nomeFluxo = a.fluxo;
                
                if (this.nomeFluxo == "aberturamaior18") {
                    this.step = 2;
                    return;
                }

                this.step = 1;
            }
        },
        async created() {


            CorporativoAPI.getTiposRepresentacao().then(response => {
                this.$store.commit("saveTiposRepresentacao", response.data.resultado);
            })
                .catch(e => {
                    console.log(e);
                });

                Common.loadInitialDataAbertura(); 

            this.step = 0;
        }

    };


</script>



<style>
    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }

    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }
    .question-container {
        display: inline-block;
        position: relative;
        /*height: 100vh;*/
    }


    .slide-up-enter-active {
        transition: all 0.25s ease-out;
    }

    .slide-up-leave-active {
        transition: all 0.25s ease-out;
    }

    .slide-up-enter-from, .slide-up-leave {
        opacity: 0;
        transform: translateY(230px);
    }

    .slide-up-leave-to, .slide-up-enter {
        opacity: 0;
        transform: translateY(-230px);
    }
</style>
